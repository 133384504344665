import {
    Box,
    Center,
    useColorModeValue,
    Text,
    Stack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";



export default function ArtworkCard(artwork) {

    return (
        <Center >
            <Link to={`/details/${artwork.id}`}>
                <Box
                    role={'group'}
                    p={2}
                    maxW={'330px'}
                    w={'full'}
                    bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={'2xl'}
                    rounded={'lg'}
                    pos={'relative'}
                    zIndex={1}>
                    <Box rounded={'lg'}>

                        <LazyLoadImage
                            rounded={'lg'}
                            objectFit={'cover'}
                            placeholderSrc={`/BG_blur.jpg`}
                            src={`/artworks/${artwork.src}`}
                        /* onClick={clicked} */
                        />
                    </Box>


                    <Stack p='4' pt='8' align={'center'}>
                        <Text color={'gray.500'}
                            fontSize={'md'}
                        >
                            {artwork.name1}

                        </Text>
                        <Text color={'gray.500'}
                            fontSize={'md'}
                        >
                            {artwork.name2}

                        </Text>

                        <Stack direction={'row'} align={'center'}>
                            <Text fontWeight={800} fontSize={'xl'}>
                                {artwork.price} USD
                            </Text>

                        </Stack>
                    </Stack>

                </Box>
            </Link>
        </Center>

    );
}