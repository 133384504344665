import { Box, Flex, Heading, Image, Stack, Text } from "@chakra-ui/react"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { braidArtworks, peArtworks } from "./artworks"
import { Layout } from "./layouts"
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function ArtworkDetails() {

    const artworks = braidArtworks.concat(peArtworks)
    const { id } = useParams()
    const artwork = artworks.find(artwork => artwork.id === id)
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <Layout>
            <Stack
                align={'center'}
                p={{ base: 4, md: 8 }}
                w='100%'
                bgImage="url(/BG.jpg)"
                bgSize="contain"
                direction={{ base: 'column', lg: 'row' }}
            >

                <Flex
                    flex={1}
                    justify={'center'}
                    align={'center'}

                >
                    {/*   <Box p={2}
                        w='100%'  > */}
                    <LazyLoadImage
                        alt={'Hero Image'}
                        placeholderSrc={`/BG_blur.jpg`}
                        /*     w={{ base: "100%", md: "80%" }}
                    h={{ base: "100%", md: "80%" }} */
                        src={`/orgArtworks/${artwork.src}`}
                    />
                    {/*  </Box> */}
                </Flex>

                <Stack flex={1}
                    /*  spacing={{ base: 5, md: 10 }} */
                    p='4'>
                    <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}>
                        {artwork.name1}

                    </Heading>
                    <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}>
                        {artwork.name2}

                    </Heading>
                    <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        color='red.400'
                        py='2'
                        fontSize={{ base: '1xl', sm: '2xl', lg: '3xl' }}>
                        {artwork.price} USD

                    </Heading>
                    <Text
                        fontSize='sm'
                        color={'red.400'}
                        fontStyle='italic'>
                        {artwork.description}
                    </Text>

                    {
                        artwork.context.map(paragraph => (
                            <Text
                                fontWeight={500}
                                fontSize={{ base: '1xl', sm: '2xl', lg: '3xl' }}>
                                {paragraph}
                            </Text>
                        ))
                    }



                    {/*      <Box p='4'>
                        <Link to="/">
                            <Button
                                w={{ base: '50%', md: '30%' }}
                                borderRadius='5'
                                boxShadow={"dark-lg"}
                                p='4'
                                colorScheme={'red'}
                            >
                                Explore More
                            </Button>
                        </Link>
                    </Box> */}
                </Stack>
            </Stack>

        </Layout>
    )
}