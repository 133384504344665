import { VStack } from '@chakra-ui/react'
import Footer from './partials/footer'
import React from 'react'

export const Layout = ({ children }) => {
  return (
    <>
      <VStack textAlign="center" spacing={'0'}>
        {children}
      </VStack>
      <Footer />
    </>
  )
}
