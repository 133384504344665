export const braidArtworks = [
  {
    id: '1',
    src: '9.jpg',
    name1: 'Repetition',
    name2: 'ထပ်တလဲလဲ',
    description: '2022,Ink and Gouache on Paper,30 x 11 inches',
    context: ['Then I realized repetition could be an ending. - Dean Young (Romantism 101)'],
    price: 400,
  },
  {
    id: '2',
    src: '2.jpg',
    name1: 'Body and Self Inside Out',
    name2: 'ကိုယ်တွင်းကိုယ်ပ',
    description: '2022,Ink and Gouache on Paper,31 x 15 inches',
    context: ['We let unimportant and materialistic things control us so much, it starts to seem like our beating heart is out of our bodies.'],
    price: 400,
  },


  {
    id: '5',
    src: '1.jpg',
    name1: 'Cycling, Circling',
    name2: 'တဝဲလည်၊ တရစ်ဝဲ',
    description: '2022,Ink and Gouache on Paper,22 x 22 inches',
    context:
      ['Life is cycling around self.  And self  also is circling around life. Life and self being attached to each other, things seem endless.'],
    price: 400,
  },
  {
    id: '6',
    src: '8.jpg',
    name1: 'The Hand ',
    name2: 'မုဒြာ',
    description: '2022,Ink and Gouache on Paper,22 x 22 inches',
    context:
      ['Where do hands keep their mem- ories of texture,  temperature,  water and blood?'],
    price: 400,
  },



  {
    id: '12',
    src: '3.jpg',
    name1: 'South West, North West, South East, North East',
    name2: 'အနောက်တောင်၊ အနောက်မြောက်၊ အရှေ့တောင်၊ အရှေ့မြောက်',
    description: '2022,Ink and Gouache on Paper,22 x 22 inches',
    context:
      ['How aware are we about trees and bees? Why did we forget about our roots? If someone asks you to point east, will you be able to do that?'],
    price: 400,
  },
  {
    id: '13',
    src: '6.jpg',
    name1: 'Balance, Balance, Balance, Balance',
    name2: 'ဆ၊ ဆ၊ ဆ၊ ဆ',
    description: '2022,Ink and Gouache on Paper,22 x 31 inches',
    context:
      ['As individuals, we balance in many  different ways. Balance self and others, balance dreams and reality. Balance desire and practicality.'],
    price: 500,
  },
  {
    id: '14',
    src: '7.jpg',
    name1: 'Tradition, Heart',
    name2: 'ထုံး၊ နှလုံး',
    description: '2022,Ink and Gouache on Paper,22 x 31 inches',
    context:
      ['When your traditions are too sacred, will you sacrifice your heart.'],
    price: 500,
  },
  {
    id: '15',
    src: '11.jpg',
    name1: 'Beyond Earth',
    name2: 'ကမ္ဘာအလွန်',
    description: '2022,Ink and Gouache on Paper,15 x 31 inches',
    context:
      ['Heart is beating so strong, soul is jumping high. Will self travel beyond earth?'],
    price: 500,
  },


  {
    id: '16',
    src: '5.jpg',
    name1: 'Bearing',
    name2: 'ကရိကထ',
    description: '2022,Ink and Gouache on Paper,22 x 31 inches',
    context:
      ['What are we letting ourselves do? What keeps us busy? The things we invest our time and selves in, the things we bear in us, are they worth it? Are they too heavy?'],
    price: 500,
  },
  {
    id: '17',
    src: '4.jpg',
    name1: 'Wisdom, Zen',
    name2: 'ဉာဏ်၊ စျာန်',
    description: '2022,Ink and Gouache on Paper,15 x 31 inches',
    context:
      ['When your head seeks for wisdom, your heart seeks for zen. Wisdom and zen, they are eternal allies, also in constant wars.'],
    price: 500,
  },
  {
    id: '18',
    src: '10.jpg',
    name1: 'Down, Up',
    name2: 'အောက်၊ အထက်',
    description: '2022,Ink and Gouache on Paper,11 x 30 inches',
    context:
      ['Up is down and down is up. The only thing that doesn’t change is the change itself.'],
    price: 400,
  },
]

export const peArtworks = [
  {
    id: '3',
    src: '12.jpg',
    name1: 'The Puppet',
    name2: 'ရုပ်သေး',
    description: '2022,Oil Pastel on Mulberry Paper,21 x 34 inches (3 pieces)',
    context:
      ['The highlight.', 'The lightness.', 'The colours.', 'The sadness.'],
    price: 1200,
  },
  {
    id: '4',
    src: '17.jpg',
    name1: 'The Archers',
    name2: 'လေးသည်တော်',
    description: '2022,Oil Pastel on Mulberry Paper,25 x 36 inches (2 pieces)',
    context:
      ['The brothers.', 'The soldiers.', 'The synchronicity.', 'The brutality.'],
    price: 1000,
  },
  {
    id: '7',
    src: '13.jpg',
    name1: 'The Male Lead Dancer',
    name2: 'မင်းသား',
    description: '2022,Oil Pastel on Mulberry Paper,36 x 36 inches',
    context:
      ['The lead.', ' The centre of the universe.', 'The glory. ', 'The responsibilities.'],
    price: 800,
  },
  {
    id: '8',
    src: '14.jpg',
    name1: 'The Maiden',
    name2: 'အပျိုတော်',
    description: '2022,Oil Pastel on Mulberry Paper,36 x 36 inches',
    context:
      ['The virgin.', 'The trickster.', 'The secrets.', 'The vibrations.'],
    price: 800,
  },
  {
    id: '9',
    src: '15.jpg',
    name1: 'The Female Lead Dancer',
    name2: 'မင်းသမီး',
    description: '2022,Oil Pastel on Mulberry Paper,36 x 36 inches',
    context:
      ['The beauty.', 'The womanhood.', 'The vibrance.', 'The silence.'],
    price: 800,
  },
  {
    id: '10',
    src: '16.jpg',
    name1: 'The Baby',
    name2: 'သူငယ်တော်',
    description: '2022,Oil Pastel on Mulberry Paper,36 x 36 inches',
    context:
      ['The comforter.', 'The joy bringer.', 'The dynamics.', 'The hidden weariness.'],
    price: 800,
  },
  {
    id: '11',
    src: '18.jpg',
    name1: 'The Comedian',
    name2: 'လူရွှင်တော်',
    description: '2022,Oil Pastel on Mulberry Paper,36 x 36 inches',
    context:
      ['The party animal.', 'The joker.', 'The laughters.', 'The darkness.'],
    price: 800,
  },
]


