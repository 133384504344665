import {
  Box,
  Center,
  Heading,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { Layout } from './layouts'


import { braidArtworks, peArtworks } from './artworks'
import React from 'react'
import ArtworkCard from './components/ArtworkCardInfo'

import { LazyLoadImage } from "react-lazy-load-image-component";

export default function App() {
  return (

    <Layout>
      <Box w="100%" bgImage="url(/BG.jpg)" bgRepeat="repeat" bgSize="contain">

        <>
          {[1, 2, 3, 4].map((page) => (
            <Center
              key={page}
              alignItems="center"
              justifyContent="center"
              bgImage="url(/BG.jpg)"
              /*   bgRepeat="repeat" */
              bgSize="contain"

            >
              <Box w={{ base: '100%', md: '80%', lg: '60%' }}
                h={{ base: '100%', md: '90%', lg: '60%' }}>
                <LazyLoadImage
                  alt={'Hero Image'}
                  align={'center'}
                  placeholderSrc={`/BG_blur.jpg`}
                  src={`/scrollPages/${page}.jpg`}
                />
              </Box>
            </Center>
          ))}



          <Box py="2">
            <Box p={4} margin={'auto'} w={'70%'}>
              <Heading
                fontWeight='600'
                color='red.400'
                fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}>
                Braids
              </Heading>
            </Box>
            <Wrap bg="transparent" p="4" spacing={5} justify="center">
              {braidArtworks.map((artwork) => (
                <WrapItem p='2' key={artwork.id}>
                  <ArtworkCard {...artwork} />
                </WrapItem>
              ))}

            </Wrap>
          </Box>
        </>

        <>
          <Center
            alignItems="center"
            justifyContent="center"
            bgImage="url(/BG.jpg)"
            bgRepeat="repeat"
            bgSize="contain"

          >
            <Box w={{ base: '100%', md: '80%', lg: '60%' }}
              h={{ base: '100%', md: '90%', lg: '60%' }}>
              <LazyLoadImage
                alt={'Hero Image'}
                align={'center'}
                placeholderSrc={`/BG_blur.jpg`}
                src={`/scrollPages/5.jpg`}

              />
            </Box>
          </Center>
          <Box py="2">
            <Box p={4} margin={'auto'} w={'90%'}>
              <Heading
                fontWeight='600'
                color='red.400'
                fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}>
                Pressure to Entertain
              </Heading>
            </Box>
            <Wrap bg="transparent" p="4" spacing={5} justify="center">
              {peArtworks.map((artwork) => (
                <WrapItem p='2' key={artwork.id}>
                  <ArtworkCard {...artwork} />
                </WrapItem>
              ))}

            </Wrap>
          </Box>
        </>
      </Box>



    </Layout>
  )
}
